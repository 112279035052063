import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import Dialog from 'react-bootstrap-dialog';
import {Calendar, momentLocalizer, Views} from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/it';
import 'moment/locale/en-gb';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Button, Fade, Form, Modal, Row} from 'react-bootstrap';
import {calendarMessages} from '../constants';
import PlayerBooking from './player-booking';
import {useTranslation} from 'react-i18next';

const PlayerBookingCalendar = ({
  addToBookingQueue,
  isLogged,
  selectedPartner,
  selectedPartnerFields,
  slots,
  isBookingSlotModalVisible,
  bookingSlot,
  hideBookingSlotModal,
  bookingSlotSelected,
  saveBooking,
  bookingFieldId,
  bookings,
  booking,
  requestDelete,
  purchaseVideo,
  showMessage,
  bookingSlots,
  bookingMultiSlots,
  profile,
  acceptPrivacy,
  getSlotsByWeek,
  getSlotsByDay,
  showVideoStore,
  showCalendar,
  filterBookableFields,
  affiliations,
  deleteBooking,
}) => {
  const {t, i18n} = useTranslation();
  const [view, setCurrentView] = useState(bookingFieldId ? Views.WEEK : Views.DAY);

  React.useEffect(() => {
    if (selectedPartner && view === Views.WEEK) getSlotsByWeek(selectedPartner.uid, bookingFieldId)();
    else if (selectedPartner && view === Views.DAY) getSlotsByDay(selectedPartner.uid, bookingFieldId)();
  }, []);

  if (!isLogged) {
    return (
      <Redirect to="/login"/>
    );
  }

  let dialog = null;
  const confirmPurchase = slot => {
    dialog.show({
      title: t('VIDEO_STORE'),
      body: t('ENTER_TO_VIDEOSTORE_TO_PURCHASE'),
      actions: [
        Dialog.Action(
          t('GO_TO_VIDEO_STORE'),
          () => showVideoStore(selectedPartner.uid, slot.fieldId, slot.startDatetime, slot.endDatetime),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('NO'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const checkPrivacyDialog = actionAfterCheck => {
    dialog.show({
      title: t('PRIVACY_CONSENT'),
      body: <Fragment>
              <Form.Label>
                <strong>{t('VIDEO_RECORDING_ACTIVE')}</strong>
              </Form.Label>
              <Form.Label>
                {t('GIVE_CONSENT_NOW')}
              </Form.Label>
            </Fragment>,
      actions: [
        Dialog.Action(
          t('I_AGREE'),
          () => {
            acceptPrivacy();
            dialog.show({
              title: t('PRIVACY_CONSENT'), 
              body: t('ABLE_TO_PURCHASE_YOUR_MATCH'),
              actions: [Dialog.Action('Ok', actionAfterCheck,'btn btn-secondary')]
            });
          },
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('NO'),
          null,          
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const insufficientWalletDialog = price => {
    dialog.show({
      title: t('INSUFFICIENT_CREDIT'),
      body: <Fragment>
              <Form.Label>
                {t('INSUFFICIENT_CREDIT_BOOKING', {price: price})}
              </Form.Label>
            </Fragment>,
      actions: [
        Dialog.Action(
          t('CLOSE_BUTTON'),
          null,          
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const missingTaxDataDialog = () => {
    dialog.show({
      title: t('TAX_DATA_MISSING'),
      body: <Fragment>
              <Form.Label>
                {t('TAX_DATA_MISSING_INFO')}
              </Form.Label>
            </Fragment>,
      actions: [
        Dialog.Action(
          t('CLOSE_BUTTON'),
          null,          
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const bookingQueue = slotId => {
    dialog.show({
      title: t('BOOKINGS'),
      body: t('BOOKING_QUEUE'),
      actions: [
        Dialog.Action(
          t('NOTIFY_ME'),
          () => addToBookingQueue(slotId),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('CLOSE_BUTTON'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const deleteBookingDialog = (bookingId, multiBooking) => {
    dialog.show({
      title: t('BOOKING_DELETE'),
      body: booking.groupBooking ? t('BOOKING_DELETE_CONFIRM_GROUP') : t('BOOKING_DELETE_CONFIRM'),
      actions: [
        Dialog.Action(
          t('CONFIRM_DELETE'),
          () => deleteBooking(multiBooking ? booking.users.filter(u => u.uid === profile.uid)[0].id : bookingId, selectedPartner.uid, multiBooking),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('DO_NOT_DELETE'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const affiliation = affiliations.filter(a => a.affiliation.partnerId === selectedPartner.uid && a.active === true && a.affiliation.active === true);

  const discount = fieldId => {
    let res = 0;
    affiliation.forEach(e => {
      if(e.affiliation.fields.some(f => f.id === fieldId) && e.affiliation.discount > res) res = e.affiliation.discount;
    });
    return res;
  }

  const localizer = momentLocalizer(moment);

  const getEventDescription = slot => {
    if (slot.state === 'reserved') {

      if (slot.videoBought) {
        if (slot.videoReady) {
          return `${slot.description} ${t('VIDEO_PURCHASED_AVAILABLE')}`;
        } else {
          return `${slot.description} ${t('PURCHASED_VIDEO_IN_PROCESSING')}`;
        }
      }

      if (slot.videoSellPermission && slot.videoPurchasable && (!slot.selectedPlayers || slot.selectedPlayers.length === 0 || slot.selectedPlayers.some(player => player.uid === profile.uid))) {
        return `${slot.description} Video acquistabile al prezzo di ${slot.videoPrice} Euro`;
      }

      return slot.description;
    }

    if (slot.state === 'unavailable') {
      return slot.description;
    }

    if (slot.presetPlaces > 1) {
      if(slot.description !== null && slot.description !== "")
        return (t('EVENT_DESCRIPTION_PLACES', {description: slot.description, places: slot.availablePlaces}) + " - " + (slot.price - (slot.price * discount(slot.fieldId) / 100)).toFixed(2) + "€ cad.") + (slot.costsPrice && slot.costsPrice > 0 ? t('SLOT_COST_PRICE', {price: (slot.costsPrice/100).toFixed(2)}) : "");
      else
        return (t('EVENT_PLACES', {places: slot.availablePlaces}) + " - " + (slot.price - (slot.price * discount(slot.fieldId) / 100)).toFixed(2) + "€ cad.") + (slot.costsPrice && slot.costsPrice > 0 ? t('SLOT_COST_PRICE', {price: (slot.costsPrice/100).toFixed(2)}) : "");  
    }

    if (slot.bookingId) {
      const myBooking = bookings.find(b => b.bookingId === slot.bookingId);
      if (myBooking) {
        return myBooking.description;
      }

      return t('BOOKED');
    }

    if (slot.price > 0 || slot.costsPrice > 0) {
      return (t('EVENT_DESCRIPTION', {price: (slot.price - (slot.price * discount(slot.fieldId) / 100)).toFixed(2)})) + (slot.costsPrice && slot.costsPrice > 0 ? t('SLOT_COST_PRICE', {price: (slot.costsPrice/100).toFixed(2)}) : "");
    }
    else return t('AVAILABLE');
  };

  const getBooking = bookingId => {
    return bookings.find(b => b.bookingId === bookingId);
  };

  const events = slots.map(slot => {
    return {
      id: slot.slotId,
      title: getEventDescription(slot),
      start: new Date(slot.startDatetime),
      end: new Date(slot.endDatetime),
      slot,
      resourceId: slot.fieldId,
      bookingId: slot.bookingId,
      state: slot.state,
      booking: slot.bookingId ? getBooking(slot.bookingId) : null
    };
  });

  const filteredFields = bookingFieldId ? selectedPartnerFields.filter(f => f.id === bookingFieldId) : selectedPartnerFields.filter(f => f.bookable === filterBookableFields);

  const resourceMap = filteredFields.map(f => {
    return {
      resourceId: f.id,
      resourceTitle: (
        <a
          href="#"
          className="btn btn-secondary"
          onClick={() => {
            setCurrentView(Views.WEEK);
            showCalendar(selectedPartner.uid, f.id)();
          }}
        >
          {f.name}
        </a>
      )
    };
  });

  const field = fieldId => selectedPartnerFields.find(f => f.id === fieldId);

  const minTime = new Date();
  minTime.setHours(7, 0, 0);
  const maxTime = new Date();
  maxTime.setHours(23, 58, 59);

  const eventStyleGetter = event => {
    let backgroundColor = event.slot.state === 'reserved' ? event.slot.color ? event.slot.color : '#ADB5BD' : event.slot.state === "unavailable" ? '#444444' : event.slot.bookingId ? '#3598DB' : '#31BC8C';

    //individuale
    if (event.slot.state === 'available' && event.slot.availablePlaces > 0) {
      backgroundColor = '#31BC8C';
    }
    //multipla singola
    if (event.slot.state === 'available' && event.slot.availablePlaces > 0 && event.slot.presetPlaces > 1 && event.slot.minimumPlayers === null) {
      backgroundColor = '#25C75B';
    }
    //multipla di gruppo
    if (event.slot.state === 'available' && event.slot.availablePlaces > 0 && event.slot.presetPlaces > 1 && event.slot.minimumPlayers !== null) {
      backgroundColor = '#308771';
    }

    if (event.slot.bookingId) {
      const myBooking = bookings.find(b => b.bookingId === event.slot.bookingId);
      if (myBooking) {
        backgroundColor = '#06438B';
      }
    }

    const color = '#fff';
    const style = {
      backgroundColor,
      borderRadius: '5px',
      opacity: 1,
      color,
      border: '1px',
      borderStyle: 'solid',
      borderColor: '#00152F',
      display: 'block'
    };
    return {
      style
    };
  };

  const checkPrivacy = (actionAfterCheck, event) => {
    if(filteredFields[0].recording && !profile.privacy1 && event.slot && event.slot.minimumPlayers === null && (profile.country === null || profile.city === null || profile.province === null || profile.cap === null)) {
      missingTaxDataDialog();
    }
    else if (filteredFields[0].recording && !profile.privacy1 && event.slot && event.slot.minimumPlayers === null) {
      checkPrivacyDialog(actionAfterCheck);
    } 
    else if (event.slot && !event.bookingId && event.slot.minimumPlayers != null && profile.user.wallet < (event.slot.price - (event.slot.price * discount(event.slot.fieldId) / 100) + ((event.slot.costsPrice / 100) / event.slot.minimumPlayers))){
      insufficientWalletDialog(event.slot.price - (event.slot.price * discount(event.slot.fieldId) / 100) + ((event.slot.costsPrice / 100) / event.slot.minimumPlayers));
    } else {
      actionAfterCheck();
    }
  }

  const handleSlotSelection = event => () => {
    let myBooking = null;
    if (event.slot.bookingId) {
      myBooking = bookings.find(b => b.bookingId === event.slot.bookingId);
    }
    if ((event.slot.state === "reserved" && (!event.slot.selectedPlayers || event.slot.selectedPlayers.length === 0 || event.slot.selectedPlayers.some(player => player.uid === profile.uid)) || (event.slot.state === "available" && myBooking && new Date(event.slot.endDatetime) < Date.now())) && event.slot.videoPurchasable && event.slot.videoSellPermission) {
      confirmPurchase(event.slot);
    } else if (event.slot.bookingId && !myBooking && event.slot.availablePlaces < 1 && new Date(event.slot.startDatetime) > Date.now()) {
      bookingQueue(event.slot.slotId);
    } else {
      bookingSlotSelected(event);
    }
  };

  const handleDeleteBooking = (bookingSlot, phoneNumber) => () => {
    if(field(bookingSlot.fieldId).unbookableHours === null)
      requestDelete(phoneNumber);
    else{
      let d = new Date(bookingSlot.startDatetime);
      d.setHours(d.getHours() - field(bookingSlot.fieldId).unbookableHours);
      if(Date.now() > d) showMessage('BOOKING',t("BOOKING_DELETE_REJECTED",{hours: field(bookingSlot.fieldId).unbookableHours}));
      else deleteBookingDialog(bookingSlot.bookingId, bookingSlot.presetPlaces != 1 && bookingSlot.minimumPlayers === null);
    }
  };
  
  const handleSlotMultiselection = event => {
    // seleziono slot nel range
    const selectedSlots = slots.filter(s => s.fieldId === event.resourceId && new Date(s.startDatetime) < event.end && new Date(s.endDatetime) > event.start);
    // la selezione non contiene elementi
    if (selectedSlots.length === 0) {      
      showMessage('BOOKING', 'BOOKING_EMPTY_SELECTION');
      return;
    }
    // Controllo che non ci siano eventi non disponibili
    const unavailableSlots = selectedSlots.filter(s => s.state === 'unavailable');
    if (unavailableSlots.length !== 0) {
      showMessage('BOOKING', 'BOOKING_EVENT_UNAVAILABLE');
      return;
    }
    // controllo che non ci siano eventi riservati
    const reservedSlots = selectedSlots.filter(s => s.state === 'reserved');
    if (reservedSlots.length !== 0) {      
      showMessage('BOOKING', 'BOOKING_EVENT_RESERVED');
      return;
    }
    // Controllo che non ci siamo eventi con la prenotazione multipla
    for (let i = 0; i < selectedSlots.length; i++) {
      if (selectedSlots[i].presetPlaces > 1) {
        showMessage('BOOKINGS', 'BOOKING_EVENT_NOT_ALLOWED');
        return;
      }
    }
    // controllo che non ci siano eventi prenotati
    const bookingSlots = selectedSlots.filter(s => s.bookingId);
    if (selectedSlots.length === 1 && bookingSlots.length === 1) {
      let myBooking = bookings.find(b => b.bookingId === bookingSlots[0].bookingId)
      if (!myBooking) {
        bookingQueue(bookingSlots[0].slotId);
        return;
      }
    } else if (selectedSlots.length > 1 && bookingSlots.length !== 0) {
      showMessage('BOOKING', 'EVENT_ALREADY_BOOKED');
      return;
    }

    // ordino per startDate
    selectedSlots.sort((a, b) => new Date(a.startDatetime) - new Date(b.startDatetime));
    // controllo che siano adiacenti (startDate === endDate precedente)
    let endDateTarget = null;
    for (let i = 0; i < selectedSlots.length; i++) {
      if (endDateTarget !== null && endDateTarget !== selectedSlots[i].startDatetime) {
        showMessage('BOOKING', 'BOOKING_SELECTION_NOT_ADJACENT');
        return;
      }

      endDateTarget = selectedSlots[i].endDatetime;
    }
    
    // Ultimo controllo!! La data di inizio del primo evento non deve essere nel passato
    if (new Date(selectedSlots[0].startDatetime) < new Date()) {
      showMessage('BOOKING', 'BOOKING_EVENT_HAS_PASSED');
      return;
    }

    // La selezione è valida, posso prenotare!!
    bookingMultiSlots(selectedSlots);
  };

  if(selectedPartnerFields.length === 0) return(
    <h4 className='text-center' style={{marginTop: 150, marginBottom: 500}}>{t('PARTNER_NO_FIELDS', {partner:selectedPartner.name})}</h4>
  );

  return (
    <Fragment>
      <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100, marginBottom: 100}}>
        <Row className="justify-content-center">
          <h4 className="text-center" style={{width: '100%'}}>{bookingFieldId ? t('PARTNER_BOOK_FIELD', {partner: selectedPartner.name, field: filteredFields[0].name}) : t('PARTNER_BOOK_FIELD_OR_BUY_VIDEO', {partner: selectedPartner.name})}</h4>
          <h6 className="text-left" style={{width: '80%'}}>{bookingFieldId ? filteredFields[0].bookingPolicy : null}</h6>
          <Calendar
            selectable
            localizer={localizer}
            culture={i18n.language ===  'en' ? 'en-GB' : i18n.language}
            defaultDate={new Date()}
            defaultView={bookingFieldId ? Views.WEEK : Views.DAY}
            view={view}
            views={bookingFieldId ? ['day', 'week'] : ['day']}
            min={minTime}
            max={maxTime}
            events={events}
            resources={resourceMap}
            step={15}
            timeslots={4}
            resourceIdAccessor="resourceId"
            resourceTitleAccessor="resourceTitle"
            eventPropGetter={eventStyleGetter}
            messages={i18n.language === 'it' ? calendarMessages : null}
            onSelectEvent={ev => checkPrivacy(handleSlotSelection(ev), ev)}
            onSelectSlot={ev => checkPrivacy(() => handleSlotMultiselection(ev), ev)}
            onNavigate={view === Views.DAY ? getSlotsByDay(selectedPartner.uid, bookingFieldId) : getSlotsByWeek(selectedPartner.uid, bookingFieldId)}
            onView={setCurrentView}
          />
        </Row>
      </Fade>
      {
        bookingSlot === null && bookingSlots === null ?
          null :
          <Modal show={isBookingSlotModalVisible} onHide={hideBookingSlotModal}>
            <Modal.Header closeButton>
              <Modal.Title>{t('BOOKING')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PlayerBooking booking={booking} save={saveBooking} partner={selectedPartner} slot={bookingSlot} slots={bookingSlots} discount={bookingSlot? discount(bookingSlot.fieldId) : discount(field(bookingSlots[0].fieldId))} field={bookingSlot ? field(bookingSlot.fieldId) : field(bookingSlots[0].fieldId)}/>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={hideBookingSlotModal}>
                {t('CLOSE_BUTTON')}
              </Button>
              {
                booking === null || !booking.bookingId ?
                  null :
                  <Fragment>
                    <Button type="button" variant="secondary" style={{marginLeft: 10}} onClick={handleDeleteBooking(bookingSlot, selectedPartner.phoneNumber)}>{t('BOOKING_DELETE')}</Button>
                    <Button
                      type="button"
                      variant="success"
                      style={{marginLeft: 10}}
                      onClick={
                        () => {
                          const targetBookingSlot = bookingSlot ? bookingSlot : bookingSlots[0];
                          showVideoStore(selectedPartner.uid, targetBookingSlot.fieldId, targetBookingSlot.startDatetime, targetBookingSlot.endDatetime);
                        }
                      }
                    >
                      {t('GO_TO_VIDEO_STORE')}
                    </Button>
                  </Fragment>
              }
            </Modal.Footer>
          </Modal>
      }
      <Dialog
        ref={el => {
          dialog = el;
        }}/>
    </Fragment>
  );
};

PlayerBookingCalendar.propTypes = {
  addToBookingQueue: PropTypes.func.isRequired,
  isLogged: PropTypes.bool,
  selectedPartner: PropTypes.object,
  selectedPartnerFields: PropTypes.array,
  slots: PropTypes.array,
  isBookingSlotModalVisible: PropTypes.bool,
  bookingSlot: PropTypes.object,
  hideBookingSlotModal: PropTypes.func.isRequired,
  bookingSlotSelected: PropTypes.func.isRequired,
  saveBooking: PropTypes.func.isRequired,
  bookingFieldId: PropTypes.number,
  bookings: PropTypes.array,
  booking: PropTypes.object,
  requestDelete: PropTypes.func.isRequired,
  purchaseVideo: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  bookingMultiSlots: PropTypes.func.isRequired,
  bookingSlots: PropTypes.array,
  profile: PropTypes.object.isRequired,
  acceptPrivacy: PropTypes.func.isRequired,
  getSlotsByWeek: PropTypes.func.isRequired,
  getSlotsByDay: PropTypes.func.isRequired,
  showVideoStore: PropTypes.func.isRequired,
  showCalendar: PropTypes.func.isRequired,
  filterBookableFields: PropTypes.bool.isRequired,
  deleteBooking: PropTypes.func.isRequired
};

PlayerBookingCalendar.defaultProps = {
  isLogged: false,
  selectedPartner: null,
  selectedPartnerFields: [],
  slots: [],
  isBookingSlotModalVisible: false,
  bookingSlot: null,
  bookingFieldId: null,
  bookings: [],
  booking: null,
  bookingSlots: null
};

export default PlayerBookingCalendar;
